import iconJavascript from "../../../assets/images/icon-javascript.png";
import iconReact from "../../../assets/images/icon-react.png";
import iconHTML from "../../../assets/images/icon-html5.png";
import iconCSS from "../../../assets/images/icon-css.png";
import iconBootstrap from "../../../assets/images/icon-bootstrap.png";
import iconNode from "../../../assets/images/icon-nodejs.png";
import iconGit from "../../../assets/images/icon-git.png";
import iconFigma from "../../../assets/images/icon-figma.png";
import iconJava from "../../../assets/images/icon-java.png";
import iconAngular from "../../../assets/images/icon-angular.png";
import iconSpringboot from "../../../assets/images/icon-springboot.png";
import iconMYSQL from "../../../assets/images/icon-mysql.png";

export const SkillsData = {
  title: "Skills & Tech",
  techSkills: [
    [
      {
        id: 1,
        icon: iconJavascript,
        name: "JavaScript",
        alt: "JavaScript icon"
      },
      {
        id: 2,
        icon: iconReact,
        name: "React.js",
        alt: "React icon"
      },
      {
        id: 3,
        icon: iconHTML,
        name: "HTML",
        alt: "HTML icon"
      },
      {
        id: 4,
        icon: iconHTML,
        name: "CSS",
        alt: "CSS icon"
      },
    ],
    [
      {
        id: 5,
        icon: iconBootstrap,
        name: "Bootstrap",
        alt: "Bootstrap icon"
      },
      {
        id: 6,
        icon: iconNode,
        name: "Node.js",
        alt: "Node icon"
      },
      {
        id: 7,
        icon: iconGit,
        name: "Git",
        alt: "Git icon"
      },
      {
        id: 8,
        icon: iconFigma,
        name: "Figma",
        alt: "Figma icon"
      },
    ],
    [
      {
        id: 9,
        icon: iconJava,
        name: "Java",
        alt: "Java icon"
      },
      {
        id: 10,
        icon: iconAngular,
        name: "Angular",
        alt: "Angular icon"
      },
      {
        id: 11,
        icon: iconSpringboot,
        name: "SpringBoot",
        alt: "SpringBoot icon"
      },
      {
        id: 12,
        icon: iconMYSQL,
        name: "My SQL",
        alt: "SQL icon"
      },
    ],
  ],
};
