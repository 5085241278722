import React from "react";
import aboutImage from "../../../assets/images/about-image.png";
import "./About.layouts.css";

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-wrapper">
        <div className="about-text-container">
          <h2>About Me</h2>
          <p>
            Im a UI Developer based in Atlanta, GA working at Delta Air Lines. I
            help in maintaining and building web applications for Delta.com
            website. Also I utilize code and visual designs to solve problems,
            create growth, and opportunities for business and brands 📈.
          </p>
        </div>
        <div className="about-img-container">
          <img src={aboutImage} alt="Hello" />
        </div>
      </div>
    </section>
  );
};

export default About;
