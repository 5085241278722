import drinkify from "../../../assets/images/project-drinkify.png";
import puertoVallarta from "../../../assets/images/project-puerto-vallarta.png";
import quickBytes from "../../../assets/images/project-quick-bytes.png";
import tranquil from "../../../assets/images/project-tranquil.png";

export const ProjectsData = [
  {
    project_id: 1,
    project_type: "01. Landing Page",
    project_name: "Puerto Vallarta",
    project_desc:
      "A landing page static website that was to showcase the wonderful city of Puerto Vallarta, Mexico. View top attractions, resturaunts, and hotels. Technologies: HTML, CSS, JavaScript",
    project_demoLink: "https://puerto-vallarta-ohviquez.netlify.app/",
    project_image: puertoVallarta
  },
  {
    project_id: 2,
    project_type: "02. Ecommerce Store",
    project_name: "Drinkify : Online Drinks",
    project_desc:
      "A concept online store that offering a delightful assortment of beverages, including the refreshing Olipop, the invigorating La Croix, the delectable Calpico, and a myriad of other enticing options. Order an array of your favorite drinks all in one place. To sweeten the deal, the store offers the added convenience of free shipping.",
    project_demoLink: "https://drinkify-it.netlify.app/",
    project_image: drinkify
  },
  {
    project_id: 3,
    project_type: "03. Full Stack Website",
    project_name: "Tranquil : HOPE Project",
    project_desc:
      "Tranquil is an e-counseling platform that makes mental health services accessible and affordable to anyone. We are committed to providing excellence and prioritize professionalism, compassion, and passion in our work.",
    project_demoLink: "https://tranquil-hh13.herokuapp.com/",
    project_image: tranquil
  },
  {
    project_id: 4,
    project_type: "04. Multi-Page Website",
    project_name: "Quick Bytes",
    project_desc:
      "A landing page website as part of bringing awareness on food insecurity to our local communities. Technologies used: HTML, CSS, JavaScript",
    project_demoLink: "https://quickbytes-code4justice.netlify.app/",
    project_image: quickBytes
  },
];
