import React from "react";
import "./Skills.layouts.css";
import { SkillsData } from "./Skills.data";

const Skills = () => {
  return (
    <section className="skills" id="skills">
      <div className="skills-wrapper">
        <h2>{SkillsData.title}</h2>

        <div className="skills-icons">
          {SkillsData.techSkills.map((content, index) => {
            return (
              <ul className="skills-icons-lists" key={index}>
                {content.map((skill, skillIndex) => {
                  return (
                    <li className="skills-icons-item" key={skillIndex}>
                      <img src={skill.icon} alt={skill.alt} />
                      <span> {skill.name} </span>
                    </li>
                  );
                })}
              </ul>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
